import React from "react"
import SEO from "../components/seo"
import Navbar from "./../components/Navigation/Navbar"
import "../components/scroll.css"

const FAQ = () =>

  <div className="faq">
  	<SEO title="Teller Training" />
  	<Navbar />
  	<br />
  	<br />
  	<br />
  	<div className="info-container">
  		<section className="transactions">
  			<div className="columns">
  				<div className="column is-centered">
  					<h1 style={{ textAlign: "center" }}>Teller FAQ</h1> </div>
  			</div>
  			<div className="columns is-multiline">
  				<div className="column is-12">
  					<h2>Transactions</h2> </div>
  				<div className="column is-12">
  					<h4>What purchasing options do customers have?</h4>
  					<ul>
  						<li>DigitalMint ONLY offers the ability to buy Bitcoin.</li>
  						<li>DigitalMint ONLY accepts cash.</li>
  					</ul>
  				</div>
  				<div className="column is-12">
  					<h4>How much can a customer purchase?</h4>
  					<ul>
  						<li>If customer desires more than $5,000, call (844) 405-6170 and provide customer’s SSN number to the Rep on the phone Up to $20,000 with Social Security Number</li>
  						<li>$2,500 maximum per transaction. Tellers can perform multiple consecutive transactions of $2,500 to reach $20,000 If customer desires more than $20,000, call (855) 274-2900</li>
  					</ul>
  				</div>
  			</div>
  		</section>
  		<br />
  		<hr />
  		<section className="questions_compliants">
  			<div className="columns is-multiline">
  				<div className="column is-12">
  					<h2>Questions & Complaints</h2> </div>
  				<div className="column is-12">
  					<h4>Customer asks about “Fees” to buy Bitcoin</h4>
  					<ol>
  						<li>Read the “Exchange Rate” that is posted on the screen. The USD-to-Bitcoin exchange rate is what the customer pays. No other fees are added</li>
  						<li>The markup is 12% of the market price</li>
  						<li>Customer still have questions? Send them to www.digitalmint.io/rates</li>
  					</ol> NOTE: We always give discounts if a customer asks!!! </div>
  				<div className="column is-12">
  					<h4>Can we perform Refunds?</h4>
  					<p>No. Bitcoin purchases are final! The customer should ALWAYS know where they are sending Bitcoin because Bitcoin transactions are irreversible.</p>
  				</div>
  			</div>
  		</section>
  		<br />
  		<hr />
  		<section className="wallets_and_addresses">
  			<div className="columns is-multiline">
  				<div className="column">
  					<h2>Wallets & Addresses</h2> </div>
  				<div className="column is-12">
  					<h4>What if a customer asks what mobile wallet to use?</h4>
  					<p>Download on iPhone or Android</p>
  					<ul>
  						<li>BRD</li>
  						<li>Blockchain</li>
  					</ul>
  				</div>
  				<div className="column is-12">
  					<h4>If a customer provides an address written on a piece of paper, can we use it?</h4>
  					<p>No. Inform the customer that they must use their own mobile digital wallet (displayed in a QR Code format) or have a new paper wallet printed. The paper wallet is NOT a receipt.</p>
  				</div>
  				<div className="column is-12">
  					<h4>What happens if a transaction keeps “Failing”?</h4>
  					<p>It is likely that the customer is either:</p>
  					<ol>
  						<li>Providing an invalid wallet address OR</li>
  						<li>Providing a wallet address other than Bitcoin (BTC).</li>
  						<li>There are numerous other digital currencies and wallets addresses such as Bitcoin Cash (BCH), Ethereum (ETH) and Litecoin (LTC) that we cannot send Bitcoin (BTC) funds to.</li>
  					</ol>
  				</div>
  			</div>
  		</section>
  		<br />
  		<hr />
  		<section className="suspicious_activity">
  			<div className="columns is-multiline">
  				<div className="column">
  					<h2>Suspicious Activity & Flags</h2> </div>
  				<div className="column is-12">
  					<h4>When should I call the Teller hotline?</h4>
  					<ul>
  						<li>Customer is using a fake ID/someone else’s ID</li>
  						<li>Customer that transacted was hesistant to provide ID or preferred to be anonymous</li>
  						<li>Customer indicates the purchase is for illegal activity</li>
  						<li>Customer is on the phone clearly being coached through the transaction</li>
  					</ul>
  				</div>
  			</div>
  		</section>
  		<br />
  		<hr /> </div>
  </div>

export default FAQ
